function About() {
  return (
    <div className="about" id="about">
      <h2>About</h2>
      <div className="about-content">
        <div className="about-img"></div>
        <div className="about-text">
          <p>
            Sean Sanchez is a multi-instrumentalist that holds a Bachelor of
            Arts in Music with an emphasis in Classical Guitar from the
            University of North Carolina Wilmington, and a teaching
            certification from the Suzuki Association of the Americas. With more
            than 25 years of playing experience, and over 10 years of teaching
            experience, he fell in love with music at age 12 upon receiving his
            first guitar.
          </p>{" "}
          <p>
            Playing guitar quickly became his greatest passion as he learned how
            to play songs by his favorite artists, especially his biggest
            inspiration, Jimi Hendrix. Later, he began studying classical guitar
            and piano and fell in love with music all over again.
          </p>{" "}
          <p>
            Sean has performed across Southern California at venues like Belly
            Up, Music Box, Casbah, Harvard and Stone, and internationally at the
            Baja Blues Fest, as well as across the state of North Carolina in
            various rock, funk, and jazz bands, with pit orchestras, and with
            the UNCW Guitar Quartet.
          </p>{" "}
          <p>
            He enjoys sharing his passion for music with students of all ages
            and skill levels. His teaching style is designed to make learning
            fun and to provide anyone with the skills necessary to experience
            the joy of music.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
